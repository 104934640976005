import {t} from '../../../utils';
import {InputType} from '../Fields';

export const stringToArray = (fieldValue: string | string[] | null): string[] => {
    if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.some((item) => item === null))) {
        return [];
    }
    return Array.isArray(fieldValue) ? fieldValue : [fieldValue];
};

const validate = (inputValue: InputType, required: boolean, validations: any) => {
    if (required && (!inputValue || (Array.isArray(inputValue) && inputValue.length === 0))) {
        return t('dynamic-components:validations.required');
    }
    if (typeof inputValue === 'number') return '';
    if (validations?.minLength && inputValue.length < validations?.minLength.value) {
        return validations?.minLength.message;
    } else if (validations?.maxLength && inputValue.length > validations?.maxLength.value) {
        return validations?.maxLength.message;
    } else {
        return '';
    }
};

export const validateInput = (value: InputType, required: boolean, validations: any) => {
    const errorMessage = required && validate(value, required, validations);
    return errorMessage ?? '';
};
