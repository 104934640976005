import styled from '@emotion/styled';

export const ConversationRowMetaDataWrapper = styled.div`
    margin-top: 8px;
    margin-left: 8px;
    margin-right: 16px;
    align-items: center;

    [data-testid='ConversationsBadgeMessagesCount'] {
        margin: 0;
        margin-left: 4px;
    }
`;
