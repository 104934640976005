export enum COLUMN_WIDTH {
    MIN_WIDTH = 100,
    MAX_WIDTH = 500,
    COLUMN_DEFAULT = 200,
    TITLE_DEFAULT = 400,
}
export enum ModeEnum {
    Client = 'Client',
    Request = 'Request',
    Layout = 'Layout',
}

export enum LicenseType {
    Licensed = 'Employee',
    Unlicensed = 'Client',
}

export enum FieldType {
    Inline = 'Inline',
    Form = 'Form',
}

export enum TabKey {
    FIELDS = 'fields',
    COMMENTS = 'Comments',
}
