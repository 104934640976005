import {colorPalette} from '@sharefiledev/antd-config';

import {SchemaProperty, UIConfig} from '../engine/types';
import {Colors} from '../model/types';
import {t} from '../utils';
import {PERMISSIONS} from '../utils/permissions/permissions';
import {FieldsEnum, getAllowedColumnFields, getAllowedCurrencies} from './fields';

export const AVATAR_LIMIT = 4;

export const API_PATH = '/io/datatables/api/v1';

export const MAX_ASSIGNEE_LIMIT = Number.MAX_VALUE;

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const NON_SORTABLE_FIELDS = ['AccountUserMultiSelect'];

export const SORT_KEYS = ['asc', 'desc'];

export const ANT_TABLE_POPOVER_COMPONENT_EXCEPTIONS =
    '.ant-picker-panel, .ant-select-dropdown, .ant-cascader-menu, .ant-flex, .ant-modal-content, .ant-picker-now-btn';

export const HEADER_COLUMN_UPDATED_FIELDS = [FieldsEnum.Select];

export enum currencySymbol {
    AED = 'dh',
    AFN = ' ؋ ',
    AUD = '$',
    EUR = '€',
    GBP = '£',
    INR = '₹',
    USD = '$',
}

export const RIGHT_ALIGNED_FIELDS = ['NumericField', 'CurrencyField'];
export enum DATA_SOURCE {
    QUICKBOOKS = 'quickbooks',
    DATATABLE = 'datatable',
}

export const DEFAULT_CONFIG: UIConfig = {
    table_icon: '',
    allow_create_record: true,
    allow_add_field: true,
    last_sync_date: '',
    allow_create_record_modal: true,
    allow_create_record_inline: true,
    allow_more_options: true,
    show_metadata_columns: false,
    empty_state: {
        buttonLabel: '',
        imageUrl: '',
        message: '',
        title: '',
    },
};

export const REFRESH_TIME_PERIOD = 900000; // 15 minutes
export const RESOURCE_TYPE = 'sharefile:datatable';

export const COLORS: Colors = {
    success: {
        value: 'Green',
        background: colorPalette.green1,
        color: colorPalette.green8,
        accentColor: colorPalette.green6,
    },
    warning: {
        value: 'Orange',
        background: colorPalette.orange1,
        color: colorPalette.orange8,
        accentColor: colorPalette.orange6,
    },
    default: {
        value: 'Neutral',
        background: colorPalette.neutral2,
        color: colorPalette.neutral8,
        accentColor: colorPalette.neutral6,
    },
    info: {
        value: 'Blue',
        background: colorPalette.blue1,
        color: colorPalette.blue8,
        accentColor: colorPalette.blue6,
    },
    critical: {
        value: 'Red',
        background: colorPalette.red1,
        color: colorPalette.red8,
        accentColor: colorPalette.red6,
    },
    highlight: {
        value: 'Lavender',
        background: colorPalette.lavender1,
        color: colorPalette.lavender8,
        accentColor: colorPalette.lavender6,
    },
    radiant: {
        value: 'Pink',
        background: colorPalette.pink1,
        color: colorPalette.pink8,
        accentColor: colorPalette.pink6,
    },
    dynamic: {
        value: 'Teal',
        background: colorPalette.teal1,
        color: colorPalette.teal8,
        accentColor: colorPalette.teal6,
    },
    radiance: {
        value: 'Yellow',
        background: colorPalette.yellow1,
        color: colorPalette.yellow8,
        accentColor: colorPalette.yellow6,
    },
};

export const MORE_PERMISSIONS = [
    PERMISSIONS.DATATABLE_MODIFY,
    PERMISSIONS.DATATABLE_CREATE,
    PERMISSIONS.DATATABLE_DELETE,
];

export const FILTER_OPTIONS = [
    'AccountUserMultiSelect',
    'Select',
    'DateOverdueField',
    'AssigneeField',
];

export const MIN_TEXT_AREA_SIZE = 2;
export const MAX_TEXT_AREA_SIZE = 4;

export const METADATA_COLUMN_NAMES = {
    created_by: 'created_by',
    created_date: 'created_date',
};

const DEFAULT_METADATA_PROPERTIES = {
    editable: false,
    isTemplateField: false,
    primary: false,
    required: false,
};

export const METADATA_COLUMNS: SchemaProperty[] = [
    {
        component: FieldsEnum.AccountUserMultiSelect,
        label: t('dynamic-components:metaDataColumnLabels.createdBy'),
        name: METADATA_COLUMN_NAMES.created_by,
        ...DEFAULT_METADATA_PROPERTIES,
    },
    {
        component: FieldsEnum.DateOverdueField,
        label: t('dynamic-components:metaDataColumnLabels.createdDate'),
        name: METADATA_COLUMN_NAMES.created_date,
        ...DEFAULT_METADATA_PROPERTIES,
    },
];

export const DEFAULT_DECIMAL_VALUE = 0;

export const RECORD_ID_COMPONENTS = ['FileStorageField'];
export const RICH_TEXT_ID = 'RichTextEditorField';

export const REMOVE_TAG_BUTTON_CLASS = 'btn-remove-tag';
export const ALLOWED_COLUMN_FIELDS = getAllowedColumnFields();
export const ALLOWED_CURRENCIES = getAllowedCurrencies();

export const MAX_COL = 2;
