import {Button, Flex} from 'antd';
import {useMemo, useRef} from 'react';

import {FILTER_OPTIONS} from '../../../constants/constants';
import {useTableContext} from '../context/TableContext';
import {ToolbarProps} from '../DataTable';
import {TableAction} from '../header';
import {Filter} from './filters';
import {CreateRowButton, ToolbarWrapper} from './toolbar.styled';

interface Props extends ToolbarProps {
    getAppliedFilters: () => string[];
    onClearFilters: (showLoader: boolean) => void;
    timeZoneOffsetMins?: number;
    roleMapper?: Record<string, string>;
}
export const Toolbar = ({
    columns,
    createButtonLabel,
    createRowButtonLoading,
    createRowButtonDisabled,
    getAppliedFilters,
    onClearFilters,
    timeZoneOffsetMins,
    roleMapper,
}: Props) => {
    const {operations, onColumnAction} = useTableContext();
    const canCreateRecord = operations?.canCreateRecord ?? false;
    const filterOptions = useMemo(
        () => columns.filter((item: any) => FILTER_OPTIONS.includes(item.component)),
        [columns]
    );
    const createRecordButtonRef = useRef<HTMLButtonElement>(null);
    const focusRecordButton = () => createRecordButtonRef.current?.focus();
    return (
        <ToolbarWrapper>
            <Flex wrap="wrap" gap="small">
                {filterOptions.length > 0 && (
                    <Filter
                        items={filterOptions}
                        getAppliedFilters={getAppliedFilters}
                        onClearFilters={onClearFilters}
                        timeZoneOffsetMins={timeZoneOffsetMins}
                        roleMapper={roleMapper}
                    />
                )}
            </Flex>
            {canCreateRecord && (
                <CreateRowButton wrap="wrap" gap="small" justify="flex-end">
                    <Button
                        onClick={() =>
                            onColumnAction({
                                action: TableAction.ADD_ROW,
                                value: '',
                                handleFocus: focusRecordButton,
                            })
                        }
                        data-testid="create-row"
                        ref={createRecordButtonRef}
                        type="primary"
                        disabled={createRowButtonDisabled}
                        loading={createRowButtonLoading}
                    >
                        {createButtonLabel}
                    </Button>
                </CreateRowButton>
            )}
        </ToolbarWrapper>
    );
};
