import {Button, Flex} from 'antd';
import dayjs from 'dayjs';
import {MouseEvent, useEffect, useRef, useState} from 'react';

import {Loader} from '../../common/loader/Loader';
import {RIGHT_ALIGNED_FIELDS} from '../../constants/constants';
import {FieldType} from '../../enum';
import {t} from '../../utils';
import {InputField} from '../Fields/Edit/InputField';
import {InputType} from '../Fields/Fields';
import {ConversationRowMetaData} from '../Fields/plugins/Conversation/ConversationRowMetaData';
import {useTableContext} from './context/TableContext';
import {ColumnDefinition, TableColumnRenderer} from './DataTable';
import {TableAction} from './header';
import FieldMapper from './Mapper';
import cellNavigation from './util/accessibility/cellNavigation';

interface CustomCellProps extends TableColumnRenderer {
    record: any;
    children: React.ReactNode;
    column: ColumnDefinition;
    editable: boolean;
    colKey: string;
    colIndex: number;
    timeZoneOffsetMins?: number;
}

const CustomCell = ({
    children,
    colKey,
    editable,
    record,
    column,
    colIndex,
    dataIndex,
    timeZoneOffsetMins,
    ...restProps
}: CustomCellProps) => {
    const cellRef = useRef<HTMLDivElement>(null);
    const fieldRef = useRef<any>(null);
    const {onColumnAction, operations, userList, enableComments, roleMapper} = useTableContext();
    const [editingField, setEditingField] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {_id: id = '', read_only} = record ?? {};
    const uniqueCellKey = `${id}_${dataIndex}`;
    const isInLineAddRecord = id === 'addrecord';
    const isCellEditable = editable && operations?.canModifyRecord;
    const shouldShowOpenBtn = colIndex == 0 && !isInLineAddRecord;

    if ((!colKey || !isCellEditable) && !shouldShowOpenBtn) {
        return (
            <td {...restProps} key={uniqueCellKey}>
                <div className="editable-cell-value-wrap view">{children}</div>
            </td>
        );
    }

    const InlineComponent = FieldMapper.table?.edit?.[column.component] || InputField;

    useEffect(() => {
        if (!fieldRef?.current) return;
        fieldRef.current.focus();
    }, [fieldRef]);

    const isEditMode = () => {
        return uniqueCellKey === editingField;
    };

    const handleOnClickCell = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        enableEditMode();
    };

    const enableEditMode = async () => {
        if (!editable || !isCellEditable) return;
        if (read_only || (isInLineAddRecord && colIndex != 0)) return;
        if (column.component === 'FileStorageField' && !record[colKey]) {
            setIsLoading(true);
            const result = await onColumnAction({
                action: TableAction.INIT_FBP,
                value: {
                    rowId: record._id,
                    colId: column.name,
                    colValue: column.name,
                    record: record,
                },
            });
            setIsLoading(false);
            if (!result?.success) return;
        }
        setEditingField(uniqueCellKey);
    };

    const cancelEditMode = (focusCell = false) => {
        setEditingField('');
        if (focusCell && cellRef && cellRef.current) {
            cellRef.current.focus();
        }
    };

    const handleSave = async (fieldVal: InputType, isValid = true, focusCell = false) => {
        if (dayjs.isDayjs(fieldVal)) {
            fieldVal = fieldVal.unix();
        }
        if (!isValid || fieldVal === record[colKey]) {
            cancelEditMode(focusCell);
            return;
        }
        setIsLoading(true);
        await onColumnAction({
            action: isInLineAddRecord ? TableAction.CREATE_INLINE_ROW : TableAction.EDIT_CELL,
            value: {rowId: id, colId: colKey, colValue: fieldVal, record: record},
        });

        cancelEditMode(focusCell);
        setIsLoading(false);
    };

    const handleKeyDown = (cellEvent: React.KeyboardEvent<HTMLDivElement>) => {
        !isEditMode() && cellNavigation(cellEvent, enableEditMode, openButtonClick);
    };

    const commonProps = {
        attributes: column?.attributes ?? {},
        fieldValue: record[colKey],
        onChange: handleSave,
        onCancel: cancelEditMode,
        forwardedRef: fieldRef,
        required: column?.required === true,
        recordId: record._id,
        onInlineUpdateCompletion: () => setEditingField(''),
        name: dataIndex,
        record,
        dataTestId: 'input-field',
        timeZoneOffsetMins: timeZoneOffsetMins,
        fieldType: FieldType.Inline,
        disabled: !isCellEditable,
        userList,
        validations: column?.validations ?? {},
        roleMapper,
    };

    const renderCell = isLoading ? (
        <Loader height="30px" />
    ) : isEditMode() ? (
        <InlineComponent {...commonProps} className="input-field" />
    ) : (
        children
    );

    const getJustifyContent = () => {
        if (isLoading) return 'center';
        return RIGHT_ALIGNED_FIELDS.includes(column.component) ? 'right' : 'left';
    };

    const focusOpenBtn = () => {
        cellRef.current?.parentElement?.focus();
        cellRef.current?.parentElement?.querySelector('button')?.focus();
    };

    const openButtonClick = (showComments?: boolean) => {
        onColumnAction({
            action: TableAction.EDIT_ROW,
            value: {rowId: id, rowValue: record, showComments: showComments},
            handleFocus: focusOpenBtn,
        });
    };
    const renderCommentsAndOpenBtn = () => {
        return (
            <div className="comments-btn-grp">
                <Button
                    aria-label={t('dynamic-components:editCell.openButtonAriaLabel')}
                    tabIndex={0}
                    onClick={(e) => {
                        e?.stopPropagation();
                        openButtonClick();
                    }}
                    onKeyDown={(e) => {
                        e?.stopPropagation();
                        if (e.key === 'Enter') openButtonClick();
                    }}
                    className="open-btn"
                    data-testid="inline-open-btn"
                >
                    {t('dynamic-components:editCell.openButton')}
                </Button>
                {enableComments && (
                    <ConversationRowMetaData
                        resourceRID={id}
                        onIconClick={() => openButtonClick(true)}
                        hidden={isEditMode()}
                    />
                )}
            </div>
        );
    };

    const renderChildNode = () => (
        <Flex
            className={`table-cell ${read_only && 'disabled-row'} ${
                isEditMode() ? 'edit' : 'view'
            }`}
            onClick={handleOnClickCell}
            onKeyDown={handleKeyDown}
            tabIndex={isCellEditable ? -1 : 0}
            align="center"
        >
            <div
                className={`editable-cell-value-wrap ${isEditMode() ? 'edit' : 'view'}`}
                role="button"
                tabIndex={0}
                aria-label={`Toggle edit for ${column.label || colKey}`}
                style={{
                    justifyContent: getJustifyContent(),
                }}
                ref={cellRef}
            >
                {renderCell}
            </div>
            {shouldShowOpenBtn && renderCommentsAndOpenBtn()}
        </Flex>
    );

    return (
        <td
            {...restProps}
            key={uniqueCellKey}
            tabIndex={-1}
            aria-labelledby={`header-${dataIndex}}`}
        >
            {renderChildNode()}
        </td>
    );
};
export default CustomCell;
