import {requestListExtensionSlots} from '@sharefiledev/request-list-pilet';

import {useAppContext} from '../../../../AppContext';
import {ConversationRowMetaDataWrapper} from './Conversation.styled';

interface Props {
    resourceRID: string;
    onIconClick?: () => void;
    hidden: boolean;
}

export const ConversationRowMetaData = ({resourceRID, onIconClick, hidden}: Props) => {
    const {piletApi} = useAppContext();
    const Extension = piletApi.Extension as React.FC<any>;

    const conversationParams = {
        resourceId: resourceRID,
        onIconClick: onIconClick,
        uiConfiguration: {
            showBadgeInline: true,
            hideTooltip: true,
        },
    };

    return (
        <ConversationRowMetaDataWrapper hidden={hidden}>
            <Extension
                name={requestListExtensionSlots.itemRowMetadata}
                params={conversationParams}
            />
        </ConversationRowMetaDataWrapper>
    );
};
