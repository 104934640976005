import {FieldsEnum} from '../../constants/fields';
import {t} from '../../utils';
import {Icons} from '../../utils/icons/icon';
import {FieldsConfigurationType} from './Fields';

const FieldsConfiguration: FieldsConfigurationType = {
    [FieldsEnum.TextInputField]: {
        label: t('dynamic-components:manageField.fields.textInput.label'),
        Icon: Icons.TextEditingTyping,
    },
    [FieldsEnum.RichTextEditorField]: {
        label: t('dynamic-components:manageField.fields.textAreaField.label'),
        Icon: Icons.TextFields,
    },
    // Commenting below to hide text area in Add column modal SFPMGT-918
    // [FieldsEnum.TextAreaField]: {
    //     label: t('dynamic-components:manageField.fields.textAreaField.label'),
    //     Icon: Icons.TextFields,
    // },
    [FieldsEnum.AccountUserMultiSelect]: {
        label: t('dynamic-components:manageField.fields.userMultiSelect.label'),
        Icon: Icons.UserSingleNeutral,
    },
    [FieldsEnum.DateOverdueField]: {
        label: t('dynamic-components:manageField.fields.dateOverdue.label'),
        Icon: Icons.CalendarMark,
    },
    [FieldsEnum.Status]: {
        label: t('dynamic-components:manageField.fields.status.label'),
        Icon: Icons.MultiSelect,
    },
    [FieldsEnum.Select]: {
        label: t('dynamic-components:manageField.fields.singleSelect.label'),
        Icon: Icons.MultiSelect,
    },
    [FieldsEnum.FileStorageField]: {
        label: t('dynamic-components:manageField.fields.fileStorage.label'),
        Icon: Icons.Paperclip,
    },
    [FieldsEnum.NumericField]: {
        label: t('dynamic-components:manageField.fields.numericField.label'),
        Icon: Icons.SignHashtag,
    },
    [FieldsEnum.CurrencyField]: {
        label: t('dynamic-components:manageField.fields.currencyField.label'),
        Icon: Icons.CoinStack,
    },
    [FieldsEnum.LinkField]: {
        label: t('dynamic-components:manageField.fields.linkField.label'),
        Icon: Icons.LinkChain,
    },
    [FieldsEnum.AssigneeField]: {
        label: t('dynamic-components:manageField.fields.assigneeField.label'),
        Icon: Icons.UserSingleNeutral,
    },
};

export default FieldsConfiguration;
