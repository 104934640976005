import {sizes} from '@sharefiledev/antd-config';
import {InputRef, Modal, Select, Typography} from 'antd';
import isEqual from 'lodash/isEqual';
import {useRef, useState} from 'react';

import {StyledInput} from '../../../../common/custom-input/CustomInput.styled';
import {ALLOWED_CURRENCIES} from '../../../../constants/constants';
import {CurrencyEnum, fieldProps, FieldsEnum} from '../../../../constants/fields';
import {FieldsArray} from '../../../../engine/types';
import {t} from '../../../../utils';
import FieldsConfiguration from '../../../Fields/Configuration';
import {useTableContext} from '../../context/TableContext';
import {ColumnDefinition, isManageColumnValid, ManageColumnModalProps} from '../../DataTable';
import {TableAction} from '..';
import {CurrencyBuilder} from './CurrencyBuilder';
import {
    ContainerFlex,
    IconStyle,
    InputWrapperFlex,
    LabelStyle,
    StyledChevronDown,
} from './ManageColumnModal.styled';
import {SelectFieldBuilder} from './SelectFieldBuilder';

const defaultIsValid = {label: false, fieldBuilder: false};
const fieldBuilderNeeded: FieldsArray = [FieldsEnum.Select, FieldsEnum.CurrencyField];
export const ManageColumnModal = ({
    editColumnSchema: ColumnSchema,
    setManageColumnModal,
}: ManageColumnModalProps) => {
    const [isValid, setIsValid] = useState<isManageColumnValid>(defaultIsValid);
    const inputRef = useRef<InputRef>(null);
    const {allowedCurrencies, allowedColumnFields, onColumnAction} = useTableContext();
    const fields: {label: JSX.Element; value: FieldsEnum}[] = [];
    const isStatusField = ColumnSchema?.attributes?.subType === FieldsEnum.Status;
    const editColumnSchema = ColumnSchema
        ? {
              ...ColumnSchema,
              component: isStatusField ? FieldsEnum.Status : ColumnSchema?.component,
          }
        : ColumnSchema;

    const defaultAddColumnFormValues: ColumnDefinition = {
        label: '',
        name: '',
        component: allowedColumnFields.length ? allowedColumnFields[0] : FieldsEnum.TextInputField,
        validations: allowedColumnFields.length
            ? fieldProps[allowedColumnFields[0]]?.validations
            : undefined,
        attributes: allowedColumnFields.length
            ? fieldProps[allowedColumnFields[0]]?.attributes
            : undefined,
    };
    const [addColumnFormValues, setAddColumnFormValues] = useState<ColumnDefinition>(
        editColumnSchema ?? defaultAddColumnFormValues
    );
    const [confirmLoading, setConfirmLoading] = useState(false);

    const getActionText = (isEdit: boolean) => ({
        title: t(`dynamic-components:manageField.${isEdit ? 'edit' : 'add'}`),
        okText: t(`dynamic-components:manageField.${isEdit ? 'saveButton' : 'createButton'}`),
    });

    const {title, okText} = getActionText(!!editColumnSchema);

    const onSaveButtonClick = async () => {
        setConfirmLoading(true);

        try {
            let modifiedColumnSchema: ColumnDefinition = addColumnFormValues;
            const component =
                modifiedColumnSchema.component === FieldsEnum.Status
                    ? FieldsEnum.Select
                    : modifiedColumnSchema.component;
            if (!editColumnSchema) {
                const date = new Date().getTime();
                const newName = `${addColumnFormValues.name}-${date}`;
                modifiedColumnSchema = {...modifiedColumnSchema, name: newName};
            }

            const actionResult = await onColumnAction({
                action: editColumnSchema ? TableAction.EDIT_COLUMN : TableAction.ADD_COLUMN,
                value: {...modifiedColumnSchema, component: component},
            });

            // Check the result from the action
            if (actionResult?.success) {
                onCancelButtonClick(); // Reset the modal
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('An error occurred while saving the column:', error);
        } finally {
            setConfirmLoading(false); // Stop the loader after everything is done
        }
    };

    const onCancelButtonClick = () => {
        setManageColumnModal(false);
        setAddColumnFormValues(defaultAddColumnFormValues);
        setIsValid(defaultIsValid);
    };

    allowedColumnFields.forEach((fieldKey) => {
        const fieldConfig = FieldsConfiguration[fieldKey];
        if (fieldConfig) {
            const {Icon, label} = fieldConfig;
            fields.push({
                label: (
                    <LabelStyle tabIndex={0} id={fieldKey}>
                        <IconStyle>
                            <Icon />
                        </IconStyle>
                        {label}
                    </LabelStyle>
                ),
                value: fieldKey,
            });
        }
    });

    const onLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const labelValue: string = e.target.value;
        const fieldName: string = labelValue.toLowerCase().replaceAll(' ', '-');

        // Update state based on editHeaderColumnName flag
        setAddColumnFormValues({
            ...addColumnFormValues,
            label: labelValue,
            ...(editColumnSchema ? {} : {name: fieldName}), // Only update name if not in edit mode
        });

        setIsValid({
            ...isValid,
            label: !!labelValue,
        });
    };

    const onFieldTypeChange = (fieldName: FieldsEnum) => {
        setAddColumnFormValues({
            ...addColumnFormValues,
            component: fieldName,
            validations: fieldProps[fieldName]?.validations,
            attributes: fieldProps[fieldName]?.attributes,
        });
    };

    const renderFieldBuilder = (fieldType: FieldsEnum) => {
        switch (fieldType) {
            case FieldsEnum.Select:
            case FieldsEnum.Status: {
                return (
                    <SelectFieldBuilder
                        editColumnSchema={editColumnSchema}
                        isValid={isValid}
                        setIsValid={setIsValid}
                        addColumnFormValues={addColumnFormValues}
                        setAddColumnFormValues={setAddColumnFormValues}
                    />
                );
            }
            case FieldsEnum.CurrencyField: {
                return (
                    <CurrencyBuilder
                        isEdit={!!editColumnSchema}
                        value={addColumnFormValues.attributes.currency}
                        onChange={onCurrencyChange}
                        allowedCurrencies={allowedCurrencies ?? ALLOWED_CURRENCIES}
                    />
                );
            }
            default: {
                return null;
            }
        }
    };

    const isFormValid = () => {
        const {fieldBuilder, label} = isValid;
        const isFieldBuilderNeeded = fieldBuilderNeeded.includes(addColumnFormValues.component);

        const formValidForNonEdit = label && (isFieldBuilderNeeded ? fieldBuilder : true);

        // For edit mode
        if (!editColumnSchema) {
            return formValidForNonEdit;
        }

        const isLabelModified = editColumnSchema.label !== addColumnFormValues.label;
        const isFieldBuilderModified =
            editColumnSchema.component !== addColumnFormValues.component ||
            !isEqual(editColumnSchema.attributes, addColumnFormValues.attributes) ||
            !isEqual(editColumnSchema.validations, addColumnFormValues.validations);

        const isFieldBuilderChangedAndValid =
            fieldBuilder && !isLabelModified && isFieldBuilderModified;

        const isLabelChangedAndValid = label && !isFieldBuilderModified && isLabelModified;

        const formValidForEdit =
            label &&
            isLabelModified &&
            (isFieldBuilderNeeded ? fieldBuilder && isFieldBuilderModified : true);

        return formValidForEdit || isLabelChangedAndValid || isFieldBuilderChangedAndValid;
    };

    const onCurrencyChange = (currency: CurrencyEnum) => {
        setIsValid({...isValid, fieldBuilder: true});
        setAddColumnFormValues({
            ...addColumnFormValues,
            attributes: {
                ...addColumnFormValues.attributes,
                currency: currency,
            },
        });
    };

    return (
        <Modal
            title={title}
            afterOpenChange={(open) => open && inputRef.current?.focus()}
            centered
            open
            okText={okText}
            cancelText={t('dynamic-components:addTab.cancel')}
            onOk={onSaveButtonClick}
            onCancel={onCancelButtonClick}
            confirmLoading={confirmLoading}
            maskClosable={false}
            okButtonProps={{
                id: editColumnSchema
                    ? `edit-field-${addColumnFormValues.component}`
                    : `create-field-${addColumnFormValues.component}`,
                disabled: !isFormValid(),
                size: 'small',
                'data-testid': 'manage-column-submit',
            }}
            cancelButtonProps={{size: 'small'}}
        >
            <ContainerFlex data-testid="manage-column-modal">
                <InputWrapperFlex>
                    <Typography.Text id="add-field-title-label">
                        {t('dynamic-components:manageField.textField.label')}
                    </Typography.Text>
                    <StyledInput
                        aria-labelledby="add-field-title-label"
                        value={addColumnFormValues.label}
                        data-testid="add-column-title"
                        id="title"
                        placeholder={t('dynamic-components:manageField.textField.placeholder')}
                        onChange={onLabelChange}
                        ref={inputRef}
                    />
                </InputWrapperFlex>
                <InputWrapperFlex>
                    <Typography.Text id="field-picker-label">
                        {t('dynamic-components:manageField.fieldPicker.label')}
                    </Typography.Text>
                    <Select
                        aria-labelledby={`field-picker-label ${addColumnFormValues?.component}`}
                        suffixIcon={<StyledChevronDown size={parseInt(sizes.base)} />}
                        value={addColumnFormValues.component}
                        options={fields} // this we need to get from props
                        onChange={onFieldTypeChange}
                        data-testid="add-column-select"
                        disabled={!!editColumnSchema}
                    />
                </InputWrapperFlex>
            </ContainerFlex>

            {addColumnFormValues.component && renderFieldBuilder(addColumnFormValues.component)}
        </Modal>
    );
};
