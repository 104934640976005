/* tslint:disable */
/* This file was generated by @citrite/translate. Manual changes will be lost. */
import { Language } from '@citrite/translate';
export type locale = 'de' | 'en' | 'es' | 'fr' | 'it' | 'ja' | 'nl' | 'pt-BR';
export const languages: Language[] = [
  {
    "key": "de",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "en",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be able to change the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani (؋)",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date"
          },
          "datePicker": {
            "label": "Date"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Category",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Long text (multi-line)"
          },
          "textInput": {
            "label": "Short text (single-line)"
          },
          "userMultiSelect": {
            "label": "Person",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete tab",
        "editTab": "Edit tab",
        "label": "More",
        "manageUsers": "Manage users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify",
          "left": "Left",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Heading 1",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Heading 2",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "Option already exists",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "es",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error while fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "fr",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "it",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "ja",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "nl",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  },
  {
    "key": "pt-BR",
    "content": {
      "addTab": {
        "add": "Add",
        "blankTab": "Blank tab",
        "cancel": "Cancel",
        "createANewTab": "Create a new tab",
        "createTab": "Create tab",
        "createTabToolTip": "Create tab",
        "defaultView": "Default view",
        "infoIcon": "Info",
        "infoMessage": "Soon you'll be abale to chnage the default view for tabs, but for now they'll all default to table view.",
        "title": "Title",
        "titlePlaceHolder": "Taxes, Expenses, etc.",
        "useATemplate": "Use a template",
        "view": {
          "calendar": "Calendar view",
          "kanban": "Kanban view",
          "list": "List view",
          "table": "Table view"
        }
      },
      "apiResponseNotification": {
        "close": "Close",
        "notifyApiFailure": "Something went wrong. Please try again!",
        "notifyCreateFieldSuccess": "Field added",
        "notifyCreateRecordSuccess": "{{tabName}} added",
        "notifyCreateTableSuccess": "Tab added",
        "notifyDeleteFieldSuccess": "Field deleted",
        "notifyDeleteRecordSuccess": "{{tabName}} deleted",
        "notifyDeleteTableSuccess": "Tab deleted",
        "notifyUpdateFieldSuccess": "Field added",
        "notifyUpdateRecordSuccess": "{{tabName}} updated",
        "notifyUpdateTableSuccess": "Tab added"
      },
      "assigneeFieldConfig": {
        "assigneeButtonlabel": "Add assignee",
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Add assignee"
      },
      "columnAction": {
        "optionsFor": "Options for",
        "sortBy": "Sort by"
      },
      "componentResponseNotification": {
        "singleSelectNotPermitted": "Single Select (Permissoin required)",
        "singleSelectRequired": "This field is required"
      },
      "createTaskButton": {
        "emptyListButtonText": "Create a Task"
      },
      "deleteField": {
        "cancel": "Cancel",
        "content": "Are you sure you wish to permanently delete the tags field?",
        "delete": "Delete",
        "title": "Delete field",
        "warningIconLabel": "Warning"
      },
      "deleteModal": {
        "ariaLabel": "Web Dialog, Delete Tab",
        "cancel": "Cancel",
        "content": "Deleting the {{tabName}} tab will permanently erase all of its data. Are you sure you want to delete it?",
        "ok": "Delete",
        "title": "Delete Tab"
      },
      "dropdownColumnOptions": {
        "editField": "Edit field",
        "moveLeft": "Move left",
        "moveRight": "Move right",
        "removeField": "Remove field",
        "sortAscending": "Sort ascending",
        "sortDescending": "Sort descending"
      },
      "editCell": {
        "openButton": "Open",
        "openButtonAriaLabel": "Open item button - Use this option to see more information"
      },
      "editTab": {
        "cancel": "Cancel",
        "modalTitle": "Edit tab",
        "title": "Title",
        "type": "Type",
        "update": "Update"
      },
      "errorMessages": {
        "placeholderError": "Error fetching record placeholder"
      },
      "fileBrowserPlugin": {
        "empty": {
          "cellText": "0",
          "title": "There aren't any files here yet"
        },
        "fileCellText": "File",
        "uploadBtnDefault": "Upload",
        "uploadBtnDropdown": "Upload more files"
      },
      "filtered_empty_state": {
        "buttonLabel": "Clear filter",
        "message": "There aren't any rows that match your filter.",
        "title": "No matching results"
      },
      "filters": {
        "applied": "applied",
        "buttonText": "Filter",
        "clear": "Clear",
        "header": "Filters"
      },
      "form": {
        "SPView": {
          "content": "Deleting this {{tabName}} will permanently erase all of its data. Are you sure you want to delete it?",
          "label": "task",
          "title": "Delete {{tabName}}"
        },
        "ariaLabel": "Web Dialog, ",
        "button": {
          "cancel": "Cancel",
          "create": "Create row",
          "delete": "Delete",
          "submit": "Submit"
        },
        "rowDelete": {
          "cancel": "Cancel",
          "content": "Deleting this row will permanently erase all of its data. Are you sure you want to delete it?",
          "delete": "Delete",
          "title": "Delete row"
        },
        "tab": {
          "fields": "Fields"
        },
        "title": {
          "create": "Create",
          "update": "Update"
        }
      },
      "formLabels": {
        "alertForm": {
          "alertLimitInfo": "Limit for maximum assignees is"
        },
        "placeholderTextForm": "Start typing..."
      },
      "header": "Dynamic Task Pilet",
      "manageField": {
        "add": "Add Field",
        "addColumnAriaLabel": "Add column button - Use this button to add a new column in this table",
        "addColumnLabel": "Add",
        "createButton": "Create field",
        "edit": "Edit field",
        "fieldPicker": {
          "label": "Type"
        },
        "fields": {
          "RichTextEditor": {
            "label": "Rich text editor"
          },
          "actions": "Actions",
          "assigneeField": {
            "label": "Assignee"
          },
          "currencyField": {
            "currency": {
              "AED": "United Arab Emirates Dirham (dh)",
              "AFN": "Afghan Aghani ( ؋ )",
              "AUD": "Australian Dollar ($)",
              "EUR": "Euro (€)",
              "GBP": "British Pound (£)",
              "INR": "Indian Rupee (₹)",
              "USD": "USD - US Dollar ($)"
            },
            "label": "Currency",
            "setType": "Currency Type"
          },
          "dateOverdue": {
            "label": "Date Field"
          },
          "datePicker": {
            "label": "Date Picker"
          },
          "fileStorage": {
            "label": "Files"
          },
          "linkField": {
            "label": "Link"
          },
          "numericField": {
            "label": "Number"
          },
          "singleSelect": {
            "addOption": "Add an option",
            "label": "Single Select",
            "optionAdded": "Label added at postion",
            "placeholder": "Enter label",
            "removeBtnAriaLabel": {
              "emptyField": "Remove empty label {{labelIndex}}",
              "fieldWithValue": "Remove label {{labelName}}"
            },
            "setOptions": "Set options:"
          },
          "status": {
            "label": "Status"
          },
          "textAreaField": {
            "label": "Text Area Field"
          },
          "textInput": {
            "label": "Text Input Field"
          },
          "userMultiSelect": {
            "label": "User Multi-Select",
            "removeBtnAriaLabel": "Remove {{userName}}"
          }
        },
        "saveButton": "Save",
        "textField": {
          "label": "Title",
          "placeholder": "Priority, Stage, Project..."
        }
      },
      "metaDataColumnLabels": {
        "createdBy": "Created by",
        "createdDate": "Created date"
      },
      "moreOptions": {
        "deleteTab": "Delete Tab",
        "editTab": "Edit Tab",
        "label": "More",
        "manageUsers": "Manage Users",
        "saveasTemplate": "Save as template"
      },
      "overdueLabels": {
        "day": {
          "plural": "days overdue",
          "singular": "day overdue"
        },
        "month": {
          "plural": "months overdue",
          "singular": "month overdue"
        },
        "week": {
          "plural": "weeks overdue",
          "singular": "week overdue"
        },
        "year": {
          "plural": "years overdue",
          "singular": "year overdue"
        }
      },
      "permissionConfig": {
        "datatables": {
          "canCreateDataTableRows": "Can create data table rows",
          "canDeleteDataTableRows": "Can delete data table rows",
          "canModifyDataTableRows": "Can modify data table rows",
          "canViewDataTableRows": "Can view data table rows",
          "sourceLabel": "Data tables"
        },
        "modalTitle": "Manage users"
      },
      "richText": {
        "areaLabel": {
          "bold": "Format Bold",
          "center": "Center Align",
          "code": "Insert Code",
          "decrement": "Decrement",
          "expand": "Enter fullscreen",
          "formattingOptions": "Formatting Options",
          "increment": "Increment",
          "italics": "Format Italics",
          "justify": "Justify Align",
          "left": "Left Align",
          "link": "Insert Link",
          "redo": "Redo",
          "right": "Right Align",
          "shrink": "Exit fullscreen",
          "strikethrough": "Format Strikethrough",
          "subscript": "Format Subscript",
          "superscript": "Format Superscript",
          "textAlignment": "Formatting options for text alignment",
          "underline": "Format Underline",
          "undo": "Undo"
        },
        "format": {
          "LargeHeading": "Large Heading",
          "bulletList": "Bullet List",
          "checkList": "Checked List",
          "codeBlock": "Code Block",
          "expand": "Enter fullscreen",
          "normal": "Normal",
          "numberList": "Numbered List",
          "quote": "Quote",
          "shrink": "Exit fullscreen",
          "smallHeading": "Small Heading",
          "smallestHeading": "Heading 3"
        },
        "placeholder": "Enter your text here...",
        "title": {
          "boldMac": "Bold (⌘B)",
          "boldWin": "Bold (Ctrl+B)",
          "decrement": "Decrement font size",
          "expand": "Enter fullscreen",
          "increment": "Increment font size",
          "italicMac": "Italic (⌘I)",
          "italicWin": "Italic (Ctrl+I)",
          "link": "Insert link",
          "redoMac": "Redo (⇧⌘Z)",
          "redoWin": "Redo (Ctrl+Y)",
          "shrink": "Exit fullscreen",
          "strikeThrough": "Strikethrough",
          "underlineMac": "Underline (⌘U)",
          "underlineWin": "Underline (Ctrl+U)",
          "undoMac": "Undo (⌘Z)",
          "undoWin": "Undo (Ctrl+Z)"
        }
      },
      "sharedModal": {
        "error": "Configuration Error"
      },
      "statusOptions": {
        "completed": {
          "label": "Completed"
        },
        "inProgress": {
          "label": "In progress"
        },
        "inReview": {
          "label": "In-review"
        },
        "notStarted": {
          "label": "Not Started"
        }
      },
      "tableLabels": {
        "createRow": "Create row",
        "tableEmptyListDescription": "Start by creating a Task for yourself or assign it to anyone on the project",
        "tableEmptyListMessage": "This project doesn't have any Tasks yet"
      },
      "toaster": {
        "row": "Row"
      },
      "userSelect": {
        "search": "Search by name or email"
      },
      "validations": {
        "invalidOption": "This option is invalid",
        "linkfield": "Something went wrong. Please try again!",
        "required": "This field is required."
      }
    }
  }
];